import add from "../icons/add.svg";
import arrow from "../icons/arrow-2.svg";
import refresh from "../icons/refresh-2.svg";
import eye from "../icons/eyew.svg";
import eyeclosed from "../icons/eye-blockedw.svg";
import wifi from "../icons/wifi.svg";
import phone from "../icons/call-calling.svg";
import devices from "../icons/devices.svg";
import flash from "../icons/flash.svg";
import eclipse1 from "../icons/eclipse1.svg";
import eclipse2 from "../icons/eclipse2.svg";
import mobile from "../icons/mobile.svg";
import headphone from "../icons/headphone.svg";
import close from "../icons/Close.svg";
import info from "../icons/info-circle.svg";
import Nav from "../components/nav";
import playstore from "../icons/playstore.png";
import applestore from "../icons/applestore.png";
import NotificationModal from "../components/notificationModal";
import { useState, useEffect, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import { FormContext } from "../providers/formValues";
import { AppDataContext } from "../providers/appData";
import { UserContext } from "../providers/userData";
import { getOS } from "../helper/getOs";
import Slider from "../components/Slider";
import React from "react";
import { motion } from "framer-motion";
import CurrencyFormat from "../helper/CurrencyFormat";

function Home() {
  const { appData, dispatch } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const { user, userDispatch } = useContext(UserContext);
  const history = useHistory();
  const [isrefrshing, setIsrefrshing] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showBalance, setShowBalance] = useState(true);
  const [showSlider, setShowSlider] = useState(false);
  document.title = appData.business.name;

  const getAppData = () => {
    fetch(localStorage.getItem("apiURL") + "data")
      .then((response) => response.json())
      .then((response) => {
        dispatch({
          type: "STORE_APP_DATA",
          appData: {
            timestamp: new Date().getTime(),
            ...response.data,
          },
        });
      });
  };

  const refreshUser = () => {
    setIsrefrshing(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(
      localStorage
        .getItem("apiURL")
        .substr(0, localStorage.getItem("apiURL").length - 1),
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        if (data.status === "success") {
          userDispatch({ type: "UPDATE_USER", action: data });
          setIsrefrshing(false);
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          history.push("/signin");
        }
      });
  };

  useEffect(() => {
    formDispatch({
      type: "CLEAR_FORM_DATA",
    });
    window.scrollTo(0, 0);

    const data = window.localStorage.getItem("Show_My_Balance");
    if (data !== null) setShowBalance(JSON.parse(data));

    user.data && refreshUser();

    const shouldFetchAppData =
      !appData.timestamp || new Date().getTime() - appData.timestamp > 1800000;
    if (shouldFetchAppData) {
      getAppData();
    }

    //this is used to prevent long loading time on mobile app. mobile app visit/index.html on first load
    // if (window.location.pathname.includes("index"))
    //   setTimeout(function () {
    //     setShowSlider(true);
    //   }, 1000);
    // else setShowSlider(true);
  }, []);

  useEffect(() => {
    window.localStorage.setItem("Show_My_Balance", JSON.stringify(showBalance));
  }, [showBalance]);

  const goTo = (where) => {
    history.push(where);
  };

  return (
    <div className="flex  dark:bg-gray-900">
      {appData.settings.notification &&
        appData.settings.notification !== user.notification && (
          <NotificationModal />
        )}

      <div>
        <Nav />
      </div>
      <div className=" flex-1 flex flex-col  h-screen  md:mb-0  md:items-start md:w-full ">
        <div className="p-5 md:mt-8  w-full">
          <div className="flex justify-between w-full md:hidden">
            <p className="font-medium text-sm dark:text-white ">
              {" "}
              {user.data
                ? ` Hello, ${user.data.firstname} ${user.data.othername} ${user.data.lastname} 😊`
                : "Hello, welcome! 😊"}
            </p>

            <div className="">
              <div className="relative  ">
                <button
                  type="button"
                  className="button flex"
                  onClick={() => {
                    userDispatch({
                      type: "UPDATE_SHOW_NOTIFICATION",
                      action: false,
                    });
                    userDispatch({
                      type: "UPDATE_USER_NOTIFICATION",
                      action: "",
                    });
                  }}
                >
                  {user.notification && user.showNotification && (
                    <p className="motion-safe:animate-ping absolute bg-red-600 justify-center rounded-full px-1 mr-4 text-white text-xs">
                      !
                    </p>
                  )}
                  <div className=" dark:stroke-white stroke-black">
                    <svg
                      width="20"
                      height="25"
                      viewBox="0 0 20 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.0277 2.3999C6.31385 2.3999 3.29562 5.4136 3.29562 9.12189V12.3597C3.29562 13.0431 3.00389 14.085 2.65607 14.6675L1.36574 16.8074C0.569107 18.1294 1.1189 19.597 2.57752 20.0899C7.41343 21.7032 12.6308 21.7032 17.4667 20.0899C18.8244 19.6418 19.4191 18.0397 18.6785 16.8074L17.3882 14.6675C17.0516 14.085 16.7599 13.0431 16.7599 12.3597V9.12189C16.7599 5.4248 13.7304 2.3999 10.0277 2.3999Z"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                      />
                      <path
                        d="M11.8 3.3C11.4984 3.17143 11.187 3.07143 10.866 3.01429C9.9319 2.84286 9.03677 2.94286 8.20001 3.3C8.48217 2.24286 9.18272 1.5 10 1.5C10.8173 1.5 11.5178 2.24286 11.8 3.3Z"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M13.6 20.3999C13.6 22.3799 11.98 23.9999 9.99999 23.9999C9.01599 23.9999 8.10399 23.5919 7.45599 22.9439C6.80799 22.2959 6.39999 21.3839 6.39999 20.3999"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                      />
                    </svg>
                  </div>
                </button>
              </div>
            </div>
          </div>

          {/* Download Card */}
          {window.localStorage.getItem("isMobileApp") == null && (
            <div className="">
              {getOS() === "ANDROID" && appData.settings.playstore_link && (
                <a target="_" href={appData.settings.playstore_link}>
                  <p className="flex justify-left items-left space-x-2">
                    {/* Download <b>{appData.business.name}</b> */}
                    <img src={playstore} className="h-16" alt="logo" />
                  </p>
                </a>
              )}
              {getOS() === "IOS" && appData.settings.applestore_link && (
                <a target="_" href={appData.settings.applestore_link}>
                  <p className="flex justify-left items-left space-x-2">
                    {/* Download <b>{appData.business.name}</b> */}
                    <img src={applestore} className="h-12" alt="logo" />
                  </p>
                </a>
              )}
            </div>
          )}
          {/* Balance card */}
          <div className="w-full p-3 mt-4 rounded-lg bg-primary-orange relative lg:flex lg:justify-between lg:items-center lg:px-7   lg:p-10">
            <div className="w-full lg:w-1/2 px-2 ">
              <img
                src={eclipse1}
                alt=""
                className="absolute top-0 right-0 z-10  "
              />
              <img
                src={eclipse2}
                alt=""
                className="absolute bottom-0 left-0 z-10  lg:w-1/4"
              />
              <div className="flex justify-between items-center relative z-20">
                {user.data ? (
                  <div className=" flex items-center">
                    <h3 className="font-medium text-sm lg:text-xl text-white">
                      {showBalance ? "Wallet balance" : "Phone Number "}
                    </h3>
                    <div
                      className=" inline ml-3 text-sm"
                      onClick={() => {
                        setShowBalance(!showBalance);
                      }}
                    >
                      {showBalance ? (
                        <img
                          src={eyeclosed}
                          alt="open"
                          className="h-5 w-5 lg:h-8 lg:w-8"
                        />
                      ) : (
                        <img
                          src={eye}
                          alt="close"
                          className="h-5 w-5 lg:h-8 lg:w-8"
                        />
                      )}
                    </div>
                  </div>
                ) : (
                  <div>
                    <h6 className="text-white font-medium text-sm lg:text-lg z-20">
                      <Link
                        to="signin"
                        className=" inline underline decoration-2 text-white  font-medium uppercase text-xs  hover:shadow-md  mr-1 mb-1"
                      >
                        Sign In
                      </Link>
                      , fund your wallet {"&"} enjoy more discounts!
                    </h6>
                    <p className="text-white font-medium text-sm lg:text-lg  ">
                      Don't have an account?{" "}
                      <Link
                        to="signUp"
                        className=" inline underline decoration-2 text-white  font-medium uppercase text-xs  hover:shadow-md  mr-1 mb-1"
                      >
                        Sign Up
                      </Link>
                    </p>
                  </div>
                )}
                <div className="lg:hidden">
                  <img
                    src={window.location.origin + "/logo.png"}
                    alt="logo"
                    className="-mb-4"
                    style={{ height: 40 }}
                  />
                </div>
              </div>
              {user.data && (
                <div className="flex items-center mt-1">
                  <p className="text-xl font-bold lg:text-4xl text-white">
                    {showBalance
                      ? `₦ ${CurrencyFormat(user.data.wallet_balance)}`
                      : user.data.phone_number}
                  </p>
                  <button
                    className="ml-3 cursor-pointer z-20"
                    onClick={() => {
                      refreshUser();
                    }}
                  >
                    <img
                      src={refresh}
                      alt="refresh"
                      className={isrefrshing ? "animate-spin" : ""}
                    />
                  </button>
                </div>
              )}
            </div>
            <div className="flex justify-between mt-6 lg:mt-0 gap-x-3 lg:space-x-6 lg:w-1/2 ">
              <button
                className="bg-primary-black flex  rounded-lg   justify-between items-center w-full py-2 px-4 z-30"
                onClick={() => setShowModal(true)}
              >
                <span className=" inline-flex  items-center p-0.5 border bg-white  border-white rounded-md">
                  <img
                    src={add}
                    alt="add"
                    className="h-5 w-5 md:h-10 md:w-10 bg-white"
                  />
                </span>

                <h4 className="font-medium text-sm lg:text-base leading-3  text-white flex flex-col justify-start items-start mr-4">
                  <label>
                    <p className="lg:inline">Fund</p>
                    <p className="mt-1 lg:inline"> Wallet</p>{" "}
                  </label>
                </h4>
              </button>
              <button
                className="bg-white flex  rounded-lg   justify-between items-center w-full py-2 px-4 z-30"
                onClick={() => history.push("transferToUser")}
              >
                <span className="inline-flex  items-center p-0.5 border bg-primary-black  border-primary-black rounded-md ">
                  <img
                    src={arrow}
                    alt="Arrow"
                    className="h-5 w-5 lg:h-10 lg:w-10 bg-primary-black"
                  />
                </span>
                {/* TODO: Remove The link and use onclick with Goto function instead.*/}

                <Link to="transferToUser">
                  <h4 className="font-medium text-sm lg:text-base  leading-3 ml-3 text-black flex flex-col lg:flex-row items-start lg:items-center lg:justify-center w-full">
                    <p className="lg:inline">Transfer</p>
                    <p className="mt-1 lg:mt-0 lg:inline">&ensp; to User</p>
                  </h4>
                </Link>
              </button>
            </div>
          </div>
          <div className="mt-3  ">
            <h2 className="font-medium md:text-2xl md:mt-6 dark:text-white ">
              Services
            </h2>
            <div className=" grid grid-cols-2 lg:grid-cols-5 gap-3  lg:gap-3   mt-1  md:mt-4 ">
              <Link to="data">
                <button className="bg-primary-black w-full flex  rounded-lg   justify-start items-center  py-2 px-4  md:flex-col md:items-start md:basis-1/3 ">
                  <span className="inline-flex  items-center p-1 border bg-primary-orange  border-primary-orange rounded-md ">
                    <img
                      src={wifi}
                      alt="wifi"
                      className=" bg-primary-orange md:h-11 md:w-11 "
                    />
                  </span>
                  <h4 className="min-w-max font-medium text-sm md:text-lg leading-3 ml-3 md:ml-0 text-white flex flex-col justify-start items-start md:mt-14 md:py-4">
                    <p>Data </p>
                    <p className="mt-1 md:mt-0">Purchase</p>
                  </h4>
                </button>
              </Link>
              <Link to="airtime">
                <button className="bg-primary-black w-full flex  rounded-lg   justify-start items-center  py-2 px-4  md:flex-col md:items-start md:basis-1/3 ">
                  <span className="inline-flex  items-center p-1 border bg-primary-orange  border-primary-orange rounded-md">
                    <img
                      src={phone}
                      alt="phone"
                      className=" bg-primary-orange md:h-11 md:w-11 "
                    />
                  </span>

                  <h4 className="min-w-max font-medium text-sm md:text-lg leading-3 ml-3 md:ml-0 text-white flex flex-col justify-start items-start md:mt-14 md:py-4">
                    <p>Airtime</p>
                    <p className="mt-1"> Purchase</p>
                  </h4>
                </button>
              </Link>
              <Link to="cable">
                <button className="bg-primary-black w-full flex  rounded-lg   justify-start items-center  py-2 px-4  md:flex-col md:items-start md:basis-1/3 ">
                  <span className="inline-flex  items-center p-1 border bg-primary-orange  border-primary-orange rounded-md">
                    <img
                      src={devices}
                      alt="devices"
                      className=" bg-primary-orange md:h-11 md:w-11 "
                    />
                  </span>

                  <h4 className="min-w-max font-medium text-sm md:text-lg leading-3 ml-3 md:ml-0 text-white flex flex-col justify-start items-start md:mt-14 md:py-4">
                    <p> Cable</p> <p className="mt-1"> Payment</p>
                  </h4>
                </button>
              </Link>
              <Link to="electricity">
                <button className="bg-primary-black w-full flex  rounded-lg   justify-start items-center  py-2 px-4  md:flex-col md:items-start md:basis-1/3 ">
                  <span className="inline-flex  items-center p-1 border bg-primary-orange  border-primary-orange rounded-md ">
                    <img
                      src={flash}
                      alt="flash"
                      className=" bg-primary-orange md:h-11 md:w-11 "
                    />
                  </span>

                  <h4 className="min-w-max font-medium text-sm md:text-lg leading-3 ml-3 md:ml-0 text-white flex flex-col justify-start items-start md:mt-14 md:py-4">
                    <p> Electricity</p> <p className="mt-1"> Payment</p>
                  </h4>
                </button>
              </Link>
              <Link className="z-40" to="code">
                <button className="bg-primary-black w-full flex  rounded-lg   justify-start items-center  py-2 px-4  md:flex-col md:items-start md:basis-1/3 ">
                  <span className="inline-flex  items-center p-1 border bg-primary-orange  border-primary-orange rounded-md ">
                    <img
                      src={mobile}
                      alt="Mobil"
                      className=" bg-primary-orange md:h-11 md:w-11 "
                    />
                  </span>

                  <h4 className="min-w-max font-medium text-sm md:text-lg leading-3 ml-3 md:ml-0 text-white flex flex-col justify-start items-start md:mt-14 md:py-4">
                    <p>Balance</p>
                    <p className="mt-1">Codes</p>
                  </h4>
                </button>
              </Link>
            </div>
          </div>
          {/* {showSlider && <Slider />} */}
          <Slider />
          {/* Faq bar for mobile view but hide on dekstop view for mobile devices view its in nav component */}
          <div className="mb-8 mx-auto bg-primary-black  text-white flex px-4 py-3 rounded-full mt-5 justify-center items-center w-56  md:hidden ">
            <Link to="faq">
              <button className="flex justify-center items-center ">
                <img src={info} alt="info" />
                <p className="font-medium text-xs py-1 px-2 pr-5  border-r-w1 bordercolorfaq ">
                  FAQS
                </p>
              </button>
            </Link>
            <Link to="contact">
              <button className="flex justify-center items-center ml-4">
                <img src={headphone} alt="headphone" />
                <p className="font-medium text-xs px-1">Contact&nbsp;Us</p>
              </button>
            </Link>
          </div>
        </div>
      </div>
      {/* Fund wallet modal */}
      {showModal ? (
        <>
          <motion.div
            className="justify-center items-center flex  fixed inset-0 z-50"
            // variants={modalAnima}
            // animate={showModal ? "open" : "closed"}
            initial={{ opacity: 0.5, y: 500 }}
            animate={{ opacity: [0, 1], y: [500, -50] }}
            // transition={{ doration: 1.5, type: "tween" }}
          >
            <div className="absolute w-full  mx-auto max-w-sm md:max-w-3xl bottom-0 md:top-1/3">
              {}
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4">
                {/*header*/}
                <div className="flex py-4 rounded-t-lg">
                  <button
                    className=" bg-transparent border-0 text-right  outline-none focus:outline-none ml-8 h-6 w-5"
                    onClick={() => setShowModal(false)}
                  >
                    <img src={close} alt="close" />
                  </button>

                  {/* ///////// */}
                  <h3 className="text-lg font-extrabold text-black text-center mx-auto">
                    Fund Wallet Via
                  </h3>
                </div>
                {/*body*/}
                <div className="relative flex flex-col text-black  mb-5">
                  <table className="table p-4 bg-white shadow rounded-lg ">
                    <tbody className="bg-secondary-blue">
                      <tr
                        className="text-secondary-black  border-b-8 border-white cursor-pointer"
                        onClick={() => goTo("/AutoAgent")}
                      >
                        <td className=" font-medium text-sm text-center">
                          AutoAgent
                        </td>
                        <td className=" p-4 ">
                          <div className=" font-medium text-xx text-primary-gray text-center">
                            Delivery
                          </div>
                          <div className="mt-1 text-center">1-10Mins</div>
                        </td>
                        <td className=" p-4 ">
                          <div className="text-xx text-primary-gray text-center">
                            Status
                          </div>
                          {appData.master_settings.fund_status_auto * 1 ===
                          0 ? (
                            <div className="mt-1 text-green-500  text-center ">
                              Active
                            </div>
                          ) : (
                            <div className="mt-1 text-red-500 text-center ">
                              Down
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr
                        className="text-secondary-black border-b-8 border-white cursor-pointer"
                        onClick={() => goTo("/monify")}
                      >
                        <td className=" font-medium text-sm text-center">
                          Monnify
                        </td>
                        <td className="border-b-2 p-4 ">
                          <div className=" font-medium text-xx text-primary-gray text-center">
                            Delivery
                          </div>
                          <div className="mt-1 text-center">1-10Mins</div>
                        </td>
                        <td className="border-b-2 p-4 ">
                          {" "}
                          <div className="text-xx text-primary-gray text-center">
                            Status
                          </div>
                          {appData.master_settings.fund_status_bank * 1 ===
                          0 ? (
                            <div className="mt-1 text-green-500 text-center">
                              Active
                            </div>
                          ) : (
                            <div className="mt-1 text-red-500 text-center">
                              Down
                            </div>
                          )}
                        </td>
                      </tr>
                      <tr
                        className="text-secondary-black cursor-pointer"
                        onClick={() => goTo("/atm")}
                      >
                        <td className=" font-medium text-sm text-center">
                          ATM
                        </td>
                        <td className="border-b-2 p-4 ">
                          <div className=" font-medium text-xx text-primary-gray text-center">
                            Delivery
                          </div>
                          <div className="mt-1 text-center">1-3Mins</div>
                        </td>
                        <td className="border-b-2 p-4 ">
                          <div className="text-xx text-primary-gray text-center">
                            Status
                          </div>

                          {appData.master_settings.fund_status_atm * 1 === 0 ? (
                            <div className="mt-1 text-green-500 text-center">
                              Active{" "}
                            </div>
                          ) : (
                            <div className="mt-1 text-red-500 text-center">
                              Down
                            </div>
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                {/*footer*/}
              </div>
            </div>
          </motion.div>

          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      ) : null}
      {/* ///// */}
    </div>
  );
}

export default Home;
