import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import "./index.css";
import AuthProvider from "./providers/auth";
import App from "./App";
import FormContextProvider from "./providers/formValues";
import AppDataContextProvider from "./providers/appData";
import UserContextProvider from "./providers/userData";
import image404 from "./icons/404.jpg";

// localStorage.setItem("apiURL", "https://api.datanow.ng/api/user/882285/"); //Clickpay
// localStorage.setItem("apiURL", "https://api.datanow.ng/api/user/937506/"); //Finbar
// localStorage.setItem("apiURL", "https://api.datanow.ng/api/user/652385/"); //DHQ

// localStorage.setItem("apiURL", "http://127.0.0.1:8000/api/user/882285/"); //Clickpay
// localStorage.setItem("apiURL", "http://127.0.0.1:8000/api/user/882285/"); //Clickpay
// localStorage.setItem("apiURL", "https://api.datanow.ng/api/user/721523/"); //Dubli

if (!global.globalThis) global.globalThis = null;

document.addEventListener(
  "touchmove",
  function (event) {
    if (event.scale !== 1) {
      event.preventDefault();
    }
  },

  false
);

ReactDOM.render(
  <BrowserRouter>
    <UserContextProvider>
      <AppDataContextProvider>
        <FormContextProvider>
          <AuthProvider>
            {!localStorage.getItem("apiURL") ? (
              <div className="center text-center mx-auto">
                <img
                  src={image404}
                  alt=""
                  className="mx-auto my-auto h-[50vh]"
                />
                <p>Error Loading App</p>
              </div>
            ) : (
              <App className="dark:bg-gray-900" />
            )}
          </AuthProvider>
        </FormContextProvider>
      </AppDataContextProvider>
    </UserContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
