import axios from "axios";

const Api = (method, url, data, callback, failback) => {
  axios({
    method: method,
    url: localStorage.getItem("apiURL") + url,
    data: data && data,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
    },
  })
    .then((response) => {
      const data = response.data;
      if (response.status === 200) {
        callback(data);
      } else {
        failback(data);
      }
    })
    .catch((error) => {
      if (!error.response) {
        failback("unable to connect to server");
      } else {
        if (
          error.response.data.message === "Token Expired" ||
          error.response.data.message === "User Not Found"
        ) {
          window.location.push("/signout");
        } else failback(error.response.data.message);
      }
    });
};
export default Api;
