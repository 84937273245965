import React, { useContext, useState, useEffect } from "react";
import bin from "../icons/bin.svg";
import bank from "../icons/bank.svg";
import wallet from "../icons/empty-wallet.svg";
import right from "../icons/right.svg";
import { ReactComponent as RightAngle } from "../icons/rightarrowdark.svg";
import loadingSmall from "../icons/loadingSmall.svg";
import { useHistory, Link } from "react-router-dom";
import { UserContext } from "../providers/userData";
import { AppDataContext } from "../providers/appData";
import { FormContext } from "../providers/formValues";
import { getOS } from "../helper/getOs";
import { toast } from "react-toastify";
import Alert from "../components/Alert";
import copy from "../icons/copy.svg";
import Layout from "../Layout";

function AutoAgentFund() {
  const { user, userDispatch } = useContext(UserContext);
  const { appData, dispatch } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const [sending, setSending] = useState(false);
  const [sendingDel, setSendingDel] = useState({ deleting: false, index: 0 });
  const [showform, setShowform] = useState(false);
  const [focused, setFocused] = useState(false);

  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const handleFocus = (e) => {
    setFocused(true);
  };

  document.title = "Fund Wallet with Autoagent-" + appData.business.name;
  const Copy = (text) => {
    navigator.clipboard.writeText(text).then(() => toast("Copied"));
  };
  useEffect(() => {
    if (!formData.ref) {
      formDispatch({
        type: "INPUTVALUES",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });
    }
    if (JSON.parse(user.data.auto_agent_banks) < 1) {
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "addBank",
          value: !formData.addBank,
        },
      });
    }
  }, []);

  useEffect(() => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "ref", value: Math.random().toString(36).slice(2) },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "account_name",
        value: "",
      },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "account_name",
        value: "",
      },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "bank",
        value: "",
      },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "account_number",
        value: "",
      },
    });

    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "Alert",
        value: { isOpen: false, message: "" },
      },
    });
  }, []);

  const deleteLinkedBank = (bank, account_number, index) => {
    setSendingDel({ deleting: true, index });
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "confirmationModal", value: { isOpen: false, text: "" } },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var urlencoded = new URLSearchParams();
    urlencoded.append("bank", String(bank));
    urlencoded.append("account_number", String(account_number));
    urlencoded.append("source", getOS());
    urlencoded.append("ref", formData.ref);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(
      localStorage.getItem("apiURL") + "delete_autoagent_bank",
      requestOptions
    )
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        setSendingDel(false);
        if (data.status === "success") {
          userDispatch({ type: "UPDATE_USER", action: data });

          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: {
                isOpen: true,
                message: data.message,
                stayOnClose: true,
              },
            },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: errorString, type: "error" },
            },
          });
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        } else {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: data.message, type: "error" },
            },
          });
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        }
      })
      .catch((error) => {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "Alert",
            value: {
              isOpen: true,
              message: "unable to connect to server",
              type: "error",
            },
          },
        });
        setSendingDel(false);
      });
  };
  const onValidSubmit = (e) => {
    e.persist();
    e.preventDefault();
    setSending(true);
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "confirmationModal", value: { isOpen: false, text: "" } },
    });

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var urlencoded = new URLSearchParams();
    urlencoded.append("account_name", String(formData.account_name));
    urlencoded.append("bank", String(formData.bank));
    urlencoded.append("account_number", String(formData.account_number));
    urlencoded.append("source", getOS());
    urlencoded.append("ref", formData.ref);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };

    fetch(localStorage.getItem("apiURL") + "add_autoagent_bank", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        setSending(false);
        setShowform(false);
        if (data.status === "success") {
          userDispatch({ type: "UPDATE_USER", action: data });
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: {
                isOpen: true,
                message: data.message,
                show: false,
                stayOnClose: true,
              },
            },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          history.push("/signout");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: errorString, type: "error" },
            },
          });

          formDispatch({
            type: "INPUTVALUES",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        } else {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: data.message, type: "error" },
            },
          });
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        }
      })
      .catch((error) => {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "Alert",
            value: {
              isOpen: true,
              message: "unable to connect to server",
              type: "error",
            },
          },
        });
        setSending(false);
      });
  };

  const history = useHistory();

  const AddBankForm = () => {
    return (
      <div className="w-full mt-8 border border-blue-300 rounded-lg shadow dark:border md:sm:max-w-md xl:p-0 dark:bg-gray-900 dark:border-gray-700">
        <div className="p-3 space-y-4 md:space-y-6 sm:p-8">
          <div className="mb-2">
            <p
              className=" text-left mb-0"
              style={{ color: appData.business.primary_color }}
            >
              <b className="text-uppercase">Fund Wallet with AutoAgent </b>
            </p>
            <hr
              style={{ borderColor: appData.business.primary_color }}
              className="mt-4"
            />
          </div>
          <p className=" text-center mb-0 mt-0 pt-0">
            Fund your wallet in 3 easy steps
          </p>
          <div className="grid grid-cols-5  mt-0 pt-0   text-center ">
            <div className="">
              <img
                src={bank}
                alt="img"
                className="p-2  mx-auto rounded-full bg-primary-orange"
              />
              <p className=" font-medium text-xs text-bold  mt-2 ">
                Tell us where you will send from
              </p>
            </div>
            <RightAngle
              fill={appData.business.primary_color}
              className="mx-auto"
            />
            <div>
              <img
                src={bank}
                alt="img"
                className="p-2  mx-auto rounded-full bg-primary-orange"
              />
              <p className=" font-medium  text-xs text-bold mt-2 ">
                Transfer into our Kuda Bank
              </p>
            </div>
            <RightAngle
              fill={appData.business.primary_color}
              className="mx-auto"
            />
            <div>
              <img
                src={wallet}
                alt="img"
                className="p-2  mx-auto rounded-full bg-primary-orange"
              />
              <p className=" font-medium  text-xs text-bold  mt-2 ">
                Your wallet will be automatically credited
              </p>
            </div>{" "}
          </div>
          <p className="text-dark mb-3" style={{ fontSize: 14 }}>
            <b>
              ⚠️ Begin by telling us the account you will be sending money from
              so we will know it is you when we receive it!
            </b>
          </p>
          <form
            className="space-y-4 md:space-y-6 "
            autoComplete="off"
            onSubmit={onValidSubmit}
          >
            {formData.error && <Alert color="danger">{formData.error}</Alert>}
            <select
              value={formData.bank}
              name="bank"
              required
              onChange={formOnChange}
              className="bg-white border border-gray-300 text-gray-900 sm:text-sm 
              rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 
              dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            >
              <option value="">--Select Bank---</option>
              {appData.banks.map((bank, index) => {
                return (
                  <option key={bank.id} value={bank.id}>
                    {bank.name}
                  </option>
                );
              })}
            </select>

            <div className="">
              <label
                for="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Account Name
                {user.data && (
                  <label
                    className="ml-2 p-1 text-white text-sm small rounded-full"
                    onClick={() => {
                      formDispatch({
                        type: "INPUTVALUES",
                        data: {
                          name: "account_name",
                          value:
                            user.data.firstname +
                            " " +
                            user.data.othername +
                            " " +
                            user.data.lastname,
                        },
                      });
                    }}
                    style={{
                      backgroundColor: appData.business.primary_color,
                    }}
                  >
                    Paste my name
                  </label>
                )}
              </label>
              <div className="mb-3 xl:w-96">
                <input
                  type="text"
                  name="account_name"
                  placeholder="full account name"
                  value={formData.account_name}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm 
              rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 
              dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  autoComplete="off"
                  inputMode="text"
                  focused={focused.toString()}
                  required
                  onBlur={handleFocus}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                />
                <span>Enter valid name</span>
              </div>
            </div>

            <div className="">
              <label
                for="email"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Account Number
              </label>
              <div className="mb-3 xl:w-96">
                <input
                  type="text"
                  inputMode="numeric"
                  placeholder="Account number"
                  name="account_number"
                  value={formData.account_number}
                  className="bg-white border border-gray-300 text-gray-900 sm:text-sm 
                  rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 
                  dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  autoComplete="off"
                  focused={focused.toString()}
                  required
                  onBlur={handleFocus}
                  onChange={(e) => {
                    formOnChange(e);
                  }}
                />
                <span>Enter valid account number</span>
              </div>
            </div>

            <button
              type="submit"
              style={{
                backgroundColor: appData.business.primary_color,
              }}
              data-mdb-ripple="true"
              data-mdb-ripple-color="light"
              disabled={sending}
              className="inline-block mb-3 xl:w-96  px-6 py-2.5 bg-blue-600 text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
            >
              {sending ? (
                <div className="flex items-center justify-center">
                  <img
                    src={loadingSmall}
                    alt="loading ..."
                    className="w-7 h-7 "
                  />
                </div>
              ) : (
                " Register Bank Account"
              )}
            </button>
          </form>
        </div>
      </div>
    );
  };

  return (
    <Layout PageName={"Fund via Auto Agent"}>
      {JSON.parse(user.data.auto_agent_banks).length > 0 ? (
        <div className="px-0 py-8">
          <div className="divide-y-2 divide-primary-gray">
            <div className="flex flex-col mt-2 space-y-3.5 ">
              <div className="flex justify-between ">
                <p className="font-medium text-sm text-gray-700 dark:text-gray-200">
                  Status:
                </p>
                <p className="font-semibold text-sm text-red-500 ">
                  {appData.master_settings.fund_status_auto * 1 === 0 ? (
                    <p className="font-semibold text-sm text-green-500 ">
                      Working
                    </p>
                  ) : (
                    <p className="font-semibold text-sm text-red-500 ">
                      Downtime
                    </p>
                  )}
                </p>
              </div>
              <div className="flex justify-between ">
                <p className="font-medium text-sm text-gray-700 dark:text-gray-200">
                  Delivery time:
                </p>
                <p className="font-semibold text-sm text-gray-700 dark:text-gray-200 ">
                  1 - 10 Minutes
                </p>
              </div>
              <div className="flex justify-between ">
                <p className="font-medium text-sm text-gray-700 dark:text-gray-200 mr-2">
                  Tranc Fee:
                </p>
                <p className="font-semibold text-sm text-gray-700 dark:text-gray-200 ">
                  ₦26.75, ₦70 when above ₦10,000
                </p>
              </div>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-5  mt-4   text-center ">
              <div className="">
                <img
                  src={bank}
                  alt="img"
                  className="p-2  mx-auto rounded-full bg-primary-orange"
                />
                <p className=" font-medium text-xs text-bold  mt-2 ">
                  You've told us the account you will send from
                </p>
              </div>
              <RightAngle
                fill={appData.business.primary_color}
                className="mx-auto"
              />
              <div>
                <img
                  src={bank}
                  alt="img"
                  className="p-2  mx-auto rounded-full bg-gray-500"
                />
                <p className=" font-medium  text-xs text-bold mt-2 ">
                  Transfer into our Kuda Bank
                </p>
              </div>
              <RightAngle
                fill={appData.business.primary_color}
                className="mx-auto"
              />
              <div>
                <img
                  src={wallet}
                  alt="img"
                  className="p-2  mx-auto rounded-full bg-gray-500"
                />
                <p className=" font-medium  text-xs text-bold  mt-2 ">
                  Your wallet will be automatically credited
                </p>
              </div>
            </div>
          </div>
          <div className=" font-medium text-sm   ">
            <div>
              <p className="mb-3 text-dark mt-4 text-center">
                Transfer into the bank account below from your registered
                accounts so we will know it is you and automatically fund your
                wallet.
              </p>

              <p
                className="text-left mt-4  text-dark text-center"
                style={{ fontSize: 15 }}
              >
                <p className="mt-2">Kuda Bank</p>
              </p>

              <p className=" mt-2  text-dark " style={{ fontSize: 26 }}>
                <div
                  className="flex gap-1 justify-center "
                  onClick={() => Copy("3000089167")}
                >
                  <p>3000089167</p>
                  <img src={copy} alt="copy" />
                </div>
              </p>

              <p
                className=" mt-2  text-dark text-center"
                style={{ fontSize: 15 }}
              >
                Systems Technologies Limited
              </p>

              <p className="mt-2 text-red-400 text-center mb-4">
                ⚠️ Sending from an account that is not registered may lead to
                delay in funding.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1  gap-2 md:grid-cols-1">
            <div className="grid grid-cols-1  gap-2 md:grid-cols-2">
              {JSON.parse(user.data.auto_agent_banks).map((bank, i) => (
                <div className="rounded overflow-hidden shadow-lg w-100 h-auto  bg-primary-orange text-white  text-sm flex flex-col">
                  <div className="flex ml-4 mt-0 items-center justify-center space-x-2 ">
                    <p className="mt-1 text-white  ">
                      {
                        appData.banks[
                          appData.banks.findIndex(
                            (iteratedBank) =>
                              iteratedBank.id * 1 === bank.bank * 1
                          )
                        ].name
                      }
                    </p>
                  </div>
                  <div className="flex ml-4 mt-1 items-center justify-center space-x-2 ">
                    <p className="mt-0 text-white  ">{bank.account_number}</p>
                  </div>
                  <div className="flex ml-4 mb-1 items-center justify-center space-x-2 mt-1">
                    <p className="mt-0 text-white   ">{bank.account_name}</p>
                  </div>
                  <label className="  justify-right items-center text-center bg-gray-500 ">
                    <button
                      className="w-6 h-6 mt-1 md:w-6 "
                      onClick={() => {
                        deleteLinkedBank(bank.bank, bank.account_number, i);
                      }}
                      disabled={sendingDel.deleting}
                    >
                      {sendingDel.deleting && sendingDel.index == i ? (
                        <div className="flex items-center justify-center">
                          <img
                            src={loadingSmall}
                            alt="loading ..."
                            className="md:flex-2 w-7 h-7"
                          />
                        </div>
                      ) : (
                        <img src={bin} alt="bin" className="md:flex  w-5 h-5" />
                      )}
                    </button>
                  </label>
                </div>
              ))}
            </div>

            {!showform && (
              <div className="grid grid-cols-1  gap-2 md:grid-cols-1">
                {JSON.parse(user.data.auto_agent_banks).length < 2 && (
                  <button
                    className="md:w-1/2 inline-block px-6 py-2.5 bg-primary-orange text-white font-medium text-xs leading-tight uppercase rounded shadow-md hover:bg-blue-700 hover:shadow-lg focus:bg-blue-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-blue-800 active:shadow-lg transition duration-150 ease-in-out"
                    type="submit"
                    style={{
                      backgroundColor: appData.business.primary_color,
                    }}
                    onClick={() => setShowform(true)}
                  >
                    <div className="flex justify-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className=" w-8 h-8  lg:visible"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 6v12m6-6H6"
                        />
                      </svg>
                    </div>
                    <p className="mb-4 lg:"> Register another Bank Account</p>
                  </button>
                )}
              </div>
            )}
          </div>
          <div className="showform">{showform && AddBankForm()}</div>
        </div>
      ) : (
        <div className="flex flex-col items-center mx-auto md:h-screen lg:py-0">
          {AddBankForm()}
        </div>
      )}
    </Layout>
  );
}

export default AutoAgentFund;
