import dstv from "../icons/dstv.png";
import startimes from "../icons/startimes.png";
import gotv from "../icons/gotv.png";
import loadingSmall from "../icons/loadingSmall.svg";
import { getOS } from "../helper/getOs";
import CurrencyFormat from "../helper/CurrencyFormat";
import { useHistory } from "react-router-dom";
import { AuthContext } from "../providers/auth";
import { FormContext } from "../providers/formValues";
import { useContext, useEffect, useState } from "react";
import { AppDataContext } from "../providers/appData";
import CablePlansModal from "../components/cablePalnsModals";
import { UserContext } from "../providers/userData";
import PaymentType from "../components/paymentType";
import ConfirmationModel from "../components/confirmationModel";
import Layout from "../Layout";

function CablePurchase() {
  const { setShowModal, showModal } = useContext(AuthContext);
  const { user } = useContext(UserContext);
  const { appData } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const [sending, setSending] = useState(false);
  const [focused, setFocused] = useState(false);
  const [Erro, setError] = useState({
    networkErro: "",
    planerro: "",
    receiverErro: "",
  });

  document.title = "Purchase Cable TV-" + appData.business.name;

  const handleFocus = (e) => {
    setFocused(true);
  };

  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const history = useHistory();

  useEffect(() => {
    if (formData.fromBeneficiaries) {
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "fromBeneficiaries",
          value: false,
        },
      });

      setShowModal((preShowModel) => !preShowModel);
      return;
    }
  }, []);
  const onPlanSelect = (network, atmPrice, walletPrice) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "network", value: network },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "atmPrice", value: atmPrice },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "walletPrice", value: walletPrice },
    });
  };
  const onValidSubmit = (e) => {
    e.preventDefault();
    if (!formData.network) {
      return setError({ networkErro: "Please pick a network!" });
    } else if (!formData.plans_name) {
      return setError({ Planerro: "Please pick a paln!" });
    }
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "ConfirmationModal",
        value: {
          isOpen: true,
          type: "Purchase Cable TV",
          description: formData.plans_name,
          receiver: formData.receiverName || formData.value_reciever_number,
          amount:
            formData.paymentMethod === "atm"
              ? formData.atmPrice
              : formData.walletPrice,
          network: formData.network,
        },
      },
    });
  };
  const verifyReceiver = (e) => {
    setSending(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", "Bearer " + user.token);
    var urlencoded = new URLSearchParams();
    urlencoded.append(
      "smartcard_number",
      String(formData.value_reciever_number)
    );
    urlencoded.append("plan_id", String(formData.cablePlan_id));
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };
    fetch(localStorage.getItem("apiURL") + "verify_smartcard", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        setSending(false);

        if (data.status === "success") {
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "receiverName", value: data.data.Customer_Name },
          });
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          history.push("/signin");
        } else {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: data.message, type: "error" },
            },
          });
        }
      })
      .catch((error) => {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "Alert",
            value: {
              isOpen: true,
              message: "unable to connect to server",
              type: "error",
            },
          },
        });
        setSending(false);
      });
  };

  const handleSubmit = (e) => {
    setSending(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var urlencoded = new URLSearchParams();
    user.data &&
      !formData.atmPayment &&
      urlencoded.append("password", String(formData.password));
    !user.data &&
      formData.atmPayment &&
      urlencoded.append("email", String(formData.email));
    urlencoded.append("plan_id", String(formData.cablePlan_id));
    urlencoded.append(
      "smartcard_number",
      String(formData.value_reciever_number)
    );
    urlencoded.append("payment_method", formData.atmPayment ? "ATM" : "WALLET");
    urlencoded.append("source", getOS());
    urlencoded.append("ref", formData.ref);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
    };
    fetch(localStorage.getItem("apiURL") + "cable_purchase", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        if (data.status === "success" && formData.atmPayment) {
          window.location = data.data.payment_url;
          return;
        }

        setSending(false);

        if (data.status === "success") {
          if (formData.atmPayment) window.location = data.data.payment_url;
          else
            formDispatch({
              type: "INPUTVALUES",
              data: {
                name: "Alert",
                value: { isOpen: true, message: data.message, show: true },
              },
            });
          setSending(false);
        } else if (
          data.message === "Token Expired" ||
          data.message === "User Not Found"
        ) {
          history.push("/signin");
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });

          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: errorString, type: "error" },
            },
          });
          setSending(false);
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        } else {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: data.message, type: "error" },
            },
          });
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        }
      })
      .catch((error) => {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "Alert",
            value: {
              isOpen: true,
              message: "unable to connect to server",
              type: "error",
            },
          },
        });
        setSending(false);
      });
  };
  useEffect(() => {
    document
      .getElementById("cable_number")
      .addEventListener("input", function () {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "value_reciever_number",
            value: this.value.replace(/\D/g, ""),
          },
        });
      });
  }, []);

  return (
    <Layout PageName={"Cable Payments"}>
      <div className="">
        <h2 className="font-medium text-sm mt-4 md:text-lg">
          Select Cable Provider
        </h2>
        <div className="grid grid-cols-4 gap-8 mt-2  ">
          <button
            className="flex  flex-col justify-center items-center "
            onClick={() => {
              onPlanSelect("DSTV");
              setError({ networkErro: "" });
            }}
          >
            {formData.ConfirmationModal ? (
              <div>
                <ConfirmationModel onConfirmationClicked={handleSubmit} />
              </div>
            ) : (
              ""
            )}
            <div className="flex  flex-col justify-center items-center focus:border-red-500 focus:ring-1 focus:ring-red-500">
              <div
                onClick={() => setShowModal(!showModal)}
                className={
                  formData.network === "DSTV"
                    ? "ring-primary-orange ring-4 rounded-lg"
                    : ""
                }
              >
                <img
                  src={dstv}
                  alt="dstv"
                  className="md:h-28  p-1 bg-white rounded-lg"
                />
              </div>
            </div>
            {/* <div className=" font-extrabold text-xs md:text-base mt-2.5">
              DStv
            </div> */}
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              onPlanSelect("STARTIMES", console.log(formData.network));
              setError({ networkErro: "" });
            }}
          >
            {" "}
            <div className="flex  flex-col justify-center items-center focus:border-red-500 focus:ring-1 focus:ring-red-500">
              <div
                onClick={() => setShowModal(!showModal)}
                className={
                  formData.network === "STARTIMES"
                    ? "ring-primary-orange ring-4 rounded-lg"
                    : ""
                }
              >
                <img
                  src={startimes}
                  alt="startimes"
                  className="md:h-28  p-1 bg-white rounded-lg"
                />
              </div>
            </div>
            {/* <div className=" font-extrabold text-xs md:text-base mt-2.5">
              Startimes
            </div> */}
          </button>
          <button
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              onPlanSelect("GOTV");
              setError({ networkErro: "" });
            }}
          >
            <div
              onClick={() => setShowModal(!showModal)}
              className={
                formData.network === "GOTV"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              <img
                src={gotv}
                alt="gotv"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            {/* <div className=" font-extrabold text-xs mt-2.5 md:text-sm">
              Gotv
            </div> */}
          </button>
        </div>
        <p className="text-red-500 text-sm">{Erro.networkErro}</p>
      </div>
      <div className="">
        <div className="mt-5">
          <div className="w-full space-y-6">
            <form className=" relative " onSubmit={onValidSubmit}>
              <div className="w-full">
                <div className=" relative ">
                  <label>
                    <p className="mt-5 font-medium text-gray-700 dark:text-gray-200   text-sm md:text-base">
                      Provider Plans
                    </p>
                    <input
                      type="text"
                      autoComplete="off"
                      className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:   mt-1 "
                      placeholder="Select plan"
                      required
                      focused={focused.toString()}
                      readOnly
                      disabled={!formData.network}
                      value={formData.plans_name}
                      onClick={() => {
                        setShowModal(!showModal);
                        setError({ Planerro: "" });
                      }}
                    />
                    <p className="text-red-500 text-sm">{Erro.Planerro}</p>
                  </label>
                  {showModal ? <CablePlansModal /> : null}
                </div>
              </div>
              <div className="w-full mt-5 mb-5">
                <label>
                  <div className="w-full">
                    <div className=" relative ">
                      <label>
                        <div className="flex justify-between">
                          <div className="font-medium text-gray-700 dark:text-gray-200   text-sm md:text-base">
                            Smart Card Number
                          </div>
                          <div className="font-medium  text-sm md:text-base">
                            {user.data &&
                              `Balance: ₦ ${CurrencyFormat(
                                user.data.wallet_balance
                              )}`}
                          </div>
                        </div>
                        <div className="flex relative mt-2.5">
                          <input
                            type="text"
                            id="cable_number"
                            name="value_reciever_number"
                            autocomplete="off"
                            className=" rounded-l-lg flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   "
                            placeholder="0000 0000 0000"
                            value={formData.value_reciever_number}
                            inputMode="numeric"
                            focused={focused.toString()}
                            pattern="^[0-9]{10,20}$"
                            required
                            onBlur={handleFocus}
                            onChange={(e) => {
                              formOnChange(e);
                              setError({ receiverErro: "" });
                            }}
                          />
                          <div className="rounded-r-md inline-flex bg-primary-orange items-center px-3 border-t text-white border-r border-b  border-slate-300 shadow-sm text-sm md:text-base">
                            <div className="" onClick={verifyReceiver}>
                              {sending ? (
                                <div className="flex items-center justify-center">
                                  <img
                                    src={loadingSmall}
                                    alt="loading ..."
                                    className="w-7 h-7 "
                                  />
                                </div>
                              ) : formData.receiverName ? (
                                `Verified`
                              ) : (
                                `Verify`
                              )}
                            </div>
                          </div>
                        </div>
                        <p className="text-xs text-right">
                          {formData.receiverName}
                        </p>
                      </label>
                    </div>
                  </div>
                  <p className="text-red-500 text-sm">{Erro.receiverErro}</p>
                </label>
              </div>

              <PaymentType
                sending={sending}
                setFocused={setFocused}
                focused={focused}
                handleFocus={handleFocus}
              />
            </form>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default CablePurchase;
