import LeftAngle from "../icons/LeftAngle.svg";
import loadingSmall from "../icons/loadingSmall.svg";
import eye from "../icons/eye.svg";
import eyeclosed from "../icons/eye-blocked.svg";
import { Link, useHistory } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { FormContext } from "../providers/formValues";
import Alert from "../components/Alert";
import { UserContext } from "../providers/userData";
import { AppDataContext } from "../providers/appData";

export default function SignUp() {
  const { formData, formDispatch } = useContext(FormContext);
  const { appData } = useContext(AppDataContext);

  const { userDispatch } = useContext(UserContext);
  const [sending, setSending] = useState(false);
  const [focused, setFocused] = useState(false);
  const [showPassword, setshowPassword] = useState(true);

  document.title = "Sign Up-" + appData.business.name;
  const handleFocus = (e) => {
    setFocused(true);
  };
  let history = useHistory();

  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSending(true);

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    var urlencoded = new URLSearchParams();
    urlencoded.append("name", String(formData.name));
    urlencoded.append("email", String(formData.email));
    urlencoded.append("phone_number", String(formData.phone_number));
    urlencoded.append("password", String(formData.password));
    formData.referrer &&
      urlencoded.append("referrer", String(formData.referrer));

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "signup", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        setSending(false);
        if (data.status === "success") {
          userDispatch({ type: "STORE_USER_DATA", user: data });
          history.push("/");
        }
        if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });

          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: errorString, type: "error" },
            },
          });
          setSending(false);
        } else {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: data.message, type: "error" },
            },
          });
          setSending(false);
        }
      })
      .catch((error) => {
        formDispatch({
          type: "SET_ERROR",
          data: "unable to connect to server",
        });

        setSending(false);
      });
  };
  useEffect(() => {
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "Alert",
        value: {
          isOpen: false,
          message: "",
        },
      },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "name",
        value: "",
      },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "phone_number",
        value: "",
      },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "email",
        value: "",
      },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "referrer",
        value: "",
      },
    });

    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "password",
        value: "",
      },
    });
  }, []);

  return (
    <>
      {" "}
      {appData && (
        <div className="bg-gradient-to-r w-screen h-full py-28 flex flex-col items-center justify-center from-primary-orange to-primary-black dark:text-white ">
          <div className=" rounded-2xl shadow  dark:bg-gray-900 bg-white">
            {formData.Alert ? <Alert message={formData.Alert.message} /> : ""}

            <div className="flex flex-col max-w-md px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-900 sm:px-6 md:px-8 lg:px-10">
              <div onClick={() => history.push("/signin")}>
                <img src={LeftAngle} alt="backicon" />
              </div>
              <Link to="/">
                <div className="   h-5 flex justify-center items-center p-6 ">
                  <img
                    src={window.location.origin + "/logo.png"}
                    alt="logo"
                    className=""
                    style={{ height: 46 }}
                  />
                  <h1 className="font-medium text-lg md:text-2xl text-primary-orange text-center ">
                    {appData.business.name}
                  </h1>
                </div>
              </Link>
              <h2 className=" font-medium text-2xl mt-2 text-primary-black text-center ">
                Create an account
              </h2>
              <p className="text-xs text-primary-gray font-medium mt-3.5"></p>
              <form onSubmit={handleSubmit}>
                <div className="relative w-full mt-12">
                  <label className="block   text-sm font-medium mb-1 ">
                    Fullname
                    <input
                      type="text"
                      name="name"
                      required
                      focused={focused.toString()}
                      onBlur={handleFocus}
                      value={formData.name}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full mt-1 py-2 px-4 bg-white text-gray-700 
              shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:  "
                      style={{ transition: "all .15s ease" }}
                    />
                    <span className="absolute  "></span>
                  </label>
                  <label className="block   text-sm font-medium mt-5">
                    Phone number
                    <input
                      type="text"
                      name="phone_number"
                      value={formData.phone_number}
                      inputMode="numeric"
                      focused={focused.toString()}
                      pattern="^[0-9]{11,11}$"
                      required
                      onBlur={handleFocus}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full mt-1 py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:  "
                      style={{ transition: "all .15s ease" }}
                    />
                    <span className="absolute  ">Enter valid phone number</span>
                  </label>
                  <label className="block    text-sm font-medium mb-2 mt-5">
                    Email address
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      required
                      focused={focused.toString()}
                      onBlur={handleFocus}
                      className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full mt-1 py-2 px-4 bg-white text-gray-700 
              shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:  "
                      style={{ transition: "all .15s ease" }}
                    />{" "}
                    <span className="absolute  ">Enter a valid Email</span>
                  </label>
                  <label className="block    text-sm font-medium mt-5">
                    Referrer Phone Number or Email (Optional)
                    <input
                      type="text"
                      className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange  mt-1"
                      style={{ transition: "all .15s ease" }}
                      name="referrer"
                      value={formData.referrer}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                    />{" "}
                  </label>
                  <label className="block  text-sm font-medium mb-2 relative mt-5">
                    Password
                    <input
                      type={showPassword ? "password" : "text"}
                      name="password"
                      value={formData.password}
                      required
                      focused={focused.toString()}
                      onBlur={handleFocus}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange  mt-1"
                      style={{ transition: "all .15s ease" }}
                    />
                    {showPassword ? (
                      <img
                        src={eye}
                        alt=""
                        className="absolute right-2 bottom-3 h-5 w-5 "
                        onClick={() => {
                          setshowPassword(!showPassword);
                        }}
                      />
                    ) : (
                      <img
                        src={eyeclosed}
                        alt=""
                        className="absolute right-2 bottom-3 h-5 w-5 "
                        onClick={() => {
                          setshowPassword(!showPassword);
                        }}
                      />
                    )}
                    <span className="absolute">please enter your password</span>
                  </label>

                  <label className="block   text-sm font-medium mt-5">
                    Confirm Password
                    <input
                      type={showPassword ? "password" : "text"}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      focused={focused.toString()}
                      onBlur={handleFocus}
                      required
                      className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange  mt-1"
                      style={{ transition: "all .15s ease" }}
                    />
                    {showPassword ? (
                      <img
                        src={eye}
                        alt=""
                        className="absolute right-2 bottom-3 h-5 w-5 "
                        onClick={() => {
                          setshowPassword(!showPassword);
                        }}
                      />
                    ) : (
                      <img
                        src={eyeclosed}
                        alt=""
                        className="absolute right-2 bottom-3 h-5 w-5 "
                        onClick={() => {
                          setshowPassword(!showPassword);
                        }}
                      />
                    )}
                    <span className="absolute"></span>
                  </label>
                </div>
                <button
                  type="submit"
                  className="py-2 px-4 bg-primary-orange   focus:ring-primary-orange text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 mt-10 rounded-lg "
                  disabled={sending}
                >
                  {sending ? (
                    <div className="flex items-center justify-center">
                      <img
                        src={loadingSmall}
                        alt="loading ..."
                        className="w-7 h-7 "
                      />
                    </div>
                  ) : (
                    `Create your account`
                  )}
                </button>
              </form>

              <div className="flex w-full "></div>
              <div className="justify-center text-sm text-center text-gray-500 flex-items-center dark:text-gray-400 mt-12">
                Have an account?
                <Link to="/signin" className="text-sm  text-primary-orange ">
                  {" "}
                  Sign in
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
