import etisalat from "../icons/9mobile.png";
import glo from "../icons/glo.png";
import airtel from "../icons/airtel.png";
import alert from "../icons/alert.svg";
import mtn from "../icons/mtn.png";
import loadingSmall from "../icons/loadingSmall.svg";
import eye from "../icons/eye.svg";
import eyeclosed from "../icons/eye-blocked.svg";
import PaymentType from "../components/paymentType";
import { useHistory, Link } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { FormContext } from "../providers/formValues";
import { getOS } from "../helper/getOs";
import { AppDataContext } from "../providers/appData";
import { UserContext } from "../providers/userData";
import ConfirmationModel from "../components/confirmationModel";
import ContactModal from "../components/ContactModal";
import CurrencyFormat from "../helper/CurrencyFormat";
import phonebook from "../icons/phonebook.png";

import Layout from "../Layout";
import DisableNotification from "../components/DisableNotification";

function AirtimePurchase() {
  const history = useHistory();
  const { user, userDispatch } = useContext(UserContext);
  const { appData, dispatch } = useContext(AppDataContext);
  const { formData, formDispatch } = useContext(FormContext);
  const [sending, setSending] = useState(false);
  const [focused, setFocused] = useState(false);
  const [showContactModal, setShowContactModals] = useState(false);
  const handleFocus = (e) => {
    setFocused(true);
  };
  document.title = "Purchase Airtime-" + appData.business.name;

  // Function  to enroll tip! check for device capability before enrollment
  const getPhoneNumber = () => {
    window.ReactNativeWebView &&
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ command: "getPhoneNumber" })
      );
  };

  const formOnChange = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: e.target.name, value: e.target.value },
    });
    calculatePrice();
  };
  const onPlanSelect = (network, atmPrice, walletPrice) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "network", value: network },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "atmPercentage", value: atmPrice },
    });
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "walletPercentage", value: walletPrice },
    });
  };

  function calculatePrice() {
    if (formData.amount && formData.network) {
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "atmPrice",
          value: (formData.atmPercentage * formData.amount) / 100,
        },
      });
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "walletPrice",
          value: (formData.walletPercentage * formData.amount) / 100,
        },
      });
    }
  }
  useEffect(() => {
    if (!formData.ref) {
      formDispatch({
        type: "INPUTVALUES",
        data: { name: "ref", value: Math.random().toString(36).slice(2) },
      });
    }
    document
      .getElementById("phone_number")
      .addEventListener("input", function () {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "value_reciever_number",
            value: this.value.replace(/\D/g, ""),
          },
        });
      });
    document.getElementById("amount").addEventListener("input", function () {
      formDispatch({
        type: "INPUTVALUES",
        data: {
          name: "amount",
          value: this.value.replace(/\D/g, ""),
        },
      });
    });
  }, []);

  const onValidSubmit = (e) => {
    e.preventDefault();
    formDispatch({
      type: "INPUTVALUES",
      data: {
        name: "ConfirmationModal",
        value: {
          isOpen: true,
          type: "Airtime Recharge",
          description: "Airtime Recharge",
          receiver: formData.value_reciever_number,
          amount: formData.amount,
          network: formData.network,
        },
      },
    });
  };
  const handleSubmit = (e) => {
    formDispatch({
      type: "INPUTVALUES",
      data: { name: "confirmationModal", value: { isOpen: false, text: "" } },
    });

    setSending(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    myHeaders.append("Accept", "application/json");
    user.data && myHeaders.append("Authorization", "Bearer " + user.token);

    var urlencoded = new URLSearchParams();
    user.data &&
      !formData.atmPayment &&
      urlencoded.append("password", String(formData.password));
    !user.data &&
      formData.atmPayment &&
      urlencoded.append("email", String(formData.email));
    urlencoded.append("network", String(formData.network));
    urlencoded.append("phone_number", String(formData.value_reciever_number));
    urlencoded.append("amount", String(formData.amount));
    urlencoded.append("payment_method", formData.atmPayment ? "ATM" : "WALLET");
    urlencoded.append("source", getOS());
    urlencoded.append("ref", formData.ref);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: urlencoded,
      //redirect: "follow",
    };
    fetch(localStorage.getItem("apiURL") + "airtime_purchase", requestOptions)
      .then((response) => (response = response.text()))
      .then((response) => {
        const data = JSON.parse(response);
        if (data.status === "success" && formData.atmPayment) {
          window.location = data.data.payment_url;
          return;
        }

        if (data.status === "success") {
          if (formData.atmPayment) window.location = data.data.payment_url;
          else
            formDispatch({
              type: "INPUTVALUES",
              data: {
                name: "Alert",
                value: { isOpen: true, message: data.message, show: true },
              },
            });
          setSending(false);
        } else if (data.errors) {
          let errorString = "";
          const objectValues = Object.values(data.errors);
          objectValues.map((error) => {
            errorString = errorString + error + ", ";
          });

          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: errorString, type: "error" },
            },
          });
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
          setSending(false);
        } else if (data.message === "User Not Found") {
          history.push("/signin");
        } else {
          formDispatch({
            type: "INPUTVALUES",
            data: {
              name: "Alert",
              value: { isOpen: true, message: data.message, type: "error" },
            },
          });
          setSending(false);
          formDispatch({
            type: "INPUTVALUES",
            data: { name: "ref", value: Math.random().toString(36).slice(2) },
          });
        }
      })

      .catch((error) => {
        formDispatch({
          type: "INPUTVALUES",
          data: {
            name: "Alert",
            value: {
              isOpen: true,
              message: "unable to connect to server",
              type: "error",
            },
          },
        });
        setSending(false);
      });
  };

  const [CloseNotice, setCloseNotice] = useState(false);
  const [Notice, setNotice] = useState("");

  return (
    <Layout PageName={"Airtime Purchase"}>
      <div>
        {CloseNotice ? (
          <DisableNotification Close={setCloseNotice} notification={Notice} />
        ) : (
          ""
        )}
        <ContactModal />
        <h2 className="font-medium text-sm mt-4 md:text-lg ">Select Network</h2>
        <div className="grid grid-cols-4 gap-6 mt-2">
          <button
            onClick={() => {
              appData.master_settings.airtime_master.MTN.status * 1 === 0
                ? onPlanSelect(
                    "MTN",
                    appData.airtime_plans.MTN.atm_price,
                    appData.airtime_plans.MTN.wallet_price
                  )
                : setCloseNotice(true);
              setNotice(
                "MTN Airtime is not available now please try again later"
              );
            }}
          >
            <div className="flex flex-col justify-center items-center focus:border-red-500 focus:ring-1 focus:ring-red-500">
              <div
                className={
                  formData.network === "MTN"
                    ? "ring-primary-orange ring-4 rounded-lg"
                    : ""
                }
              >
                {appData.master_settings.airtime_master.MTN.status * 1 === 0 ? (
                  ""
                ) : (
                  <p className=" absolute  items-center justify-center rounded-full  text-white text-base">
                    <span className="relative inline-flex rounded-full h-9 w-9 bg-red-500">
                      <img src={alert} alt="alert" />
                    </span>
                  </p>
                )}
                <img
                  src={mtn}
                  alt="mtn"
                  className="md:h-28  p-1 bg-white rounded-lg"
                />
              </div>
              {/* <div className=" font-extrabold text-xs md:text-base mt-2.5">
                MTN
              </div> */}
              <div className="flex flex-col justify-center items-center text-primary-gray text-xx  mt-2.5">
                <span>
                  {" "}
                  {appData.airtime_plans.MTN.wallet_price < 100 && (
                    <p>{100 - appData.airtime_plans.MTN.wallet_price}%</p>
                  )}
                </span>
                <span>Discount</span>
              </div>
            </div>
          </button>

          <div
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              appData.master_settings.airtime_master.GLO.status * 1 === 0
                ? onPlanSelect(
                    "GLO",
                    appData.airtime_plans.GLO.atm_price,
                    appData.airtime_plans.GLO.wallet_price
                  )
                : setCloseNotice(true);
              setNotice(
                "GLO Airtime is not available now please try again later"
              );
            }}
          >
            <div
              className={
                formData.network === "GLO"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              {appData.master_settings.airtime_master.GLO.status * 1 === 0 ? (
                ""
              ) : (
                <p className=" absolute  items-center justify-center rounded-full  text-white text-base">
                  <span className="relative inline-flex rounded-full h-9 w-9 bg-red-500">
                    <img src={alert} alt="alert" />
                  </span>
                </p>
              )}
              <img
                src={glo}
                alt="glo"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            {/* <div className=" font-extrabold text-xs mt-2.5 md:text-base">
              GLO
            </div> */}
            <div className="flex flex-col justify-center items-center text-primary-gray text-xx md:text-sm  mt-2.5">
              <span>
                {" "}
                {appData.airtime_plans.GLO.wallet_price < 100 && (
                  <p className="">
                    {100 - appData.airtime_plans.GLO.wallet_price}%
                  </p>
                )}
              </span>
              <span>Discount</span>
            </div>
          </div>
          <div
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              appData.master_settings.airtime_master.AIRTEL.status * 1 === 0
                ? onPlanSelect(
                    "AIRTEL",
                    appData.airtime_plans.AIRTEL.atm_price,
                    appData.airtime_plans.AIRTEL.wallet_price
                  )
                : setCloseNotice(true);
              setNotice(
                "GLO Airtime is not available now please try again later"
              );
            }}
          >
            <div
              className={
                formData.network === "AIRTEL"
                  ? "ring-primary-orange ring-4 rounded-lg"
                  : ""
              }
            >
              {appData.master_settings.airtime_master.AIRTEL.status * 1 ===
              0 ? (
                ""
              ) : (
                <p className=" absolute  items-center justify-center rounded-full  text-white text-base">
                  <span className="relative inline-flex rounded-full h-9 w-9 bg-red-500">
                    <img src={alert} alt="alert" />
                  </span>
                </p>
              )}
              <img
                src={airtel}
                alt="airtel"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            {/* <div className=" font-extrabold text-xs mt-2.5 md:text-base">
              Airtel
            </div> */}
            <div className="flex flex-col justify-center items-center text-primary-gray text-xx mt-2.5">
              <span>
                {" "}
                {appData.airtime_plans.AIRTEL.wallet_price < 100 && (
                  <p>{100 - appData.airtime_plans.AIRTEL.wallet_price}%</p>
                )}
              </span>
              <span>Discount</span>
            </div>
          </div>
          <div
            className="flex  flex-col justify-center items-center"
            onClick={() => {
              appData.master_settings.airtime_master.ETISALAT.status * 1 === 0
                ? onPlanSelect(
                    "ETISALAT",
                    appData.airtime_plans.ETISALAT.atm_price,
                    appData.airtime_plans.ETISALAT.wallet_price
                  )
                : setCloseNotice(true);
              setNotice(
                "ETISALAT Airtime is not available now please try again later"
              );
            }}
          >
            {" "}
            <div
              className={
                formData.network === "ETISALAT"
                  ? "ring-primary-orange ring-4 rounded-lg "
                  : ""
              }
            >
              {appData.master_settings.airtime_master.ETISALAT.status * 1 ===
              0 ? (
                ""
              ) : (
                <p className=" absolute  items-center justify-center rounded-full  text-white text-base">
                  <span className="relative inline-flex rounded-full h-9 w-9 bg-red-500">
                    <img src={alert} alt="alert" />
                  </span>
                </p>
              )}
              <img
                src={etisalat}
                alt="etisalat"
                className="md:h-28  p-1 bg-white rounded-lg"
              />
            </div>
            {/* <div className=" font-extrabold text-xs mt-2.5 md:text-base">
              9mobile
            </div> */}
            <div className="flex flex-col justify-center items-center text-primary-gray text-xx  mt-2.5">
              <span>
                {" "}
                {appData.airtime_plans.ETISALAT.wallet_price < 100 && (
                  <p>{100 - appData.airtime_plans.ETISALAT.wallet_price}%</p>
                )}
              </span>
              <span>Discount</span>
            </div>
          </div>
        </div>
      </div>
      <form onSubmit={onValidSubmit}>
        <div className="">
          <div className="mt-5">
            {formData.ConfirmationModal && (
              <div>
                <ConfirmationModel onConfirmationClicked={handleSubmit} />
              </div>
            )}

            <div className="w-full space-y-6">
              <div className="w-full">
                <div className=" relative ">
                  <label>
                    <div className="flex justify-between mb-2">
                      <div className="font-medium text-gray-700 dark:text-gray-200  text-sm md:text-base">
                        Phone Number
                        {user.data && (
                          <label
                            className="ml-1 p-1 text-white rounded-lg "
                            onClick={() => {
                              formDispatch({
                                type: "INPUTVALUES",
                                data: {
                                  name: "value_reciever_number",
                                  value: user.data.phone_number,
                                },
                              });
                            }}
                            style={{
                              backgroundColor: appData.business.primary_color,
                            }}
                          >
                            Paste my number
                          </label>
                        )}
                      </div>
                    </div>

                    <div className="w-full">
                      <div className=" relative ">
                        <label>
                          <div
                            style={{
                              marginRight:
                                localStorage.getItem("isMobileApp") && "44px",
                            }}
                          >
                            <input
                              type="text"
                              id="phone_number"
                              className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4  bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange focus:   mt-1"
                              placeholder="08X XXX XXXX"
                              focused={focused.toString()}
                              required
                              autoComplete="off"
                              name="value_reciever_number"
                              value={formData.value_reciever_number}
                              inputMode="numeric"
                              pattern="^[0-9]{11,11}$"
                              onBlur={handleFocus}
                              onChange={(e) => {
                                formOnChange(e);
                              }}
                            />
                          </div>
                          {localStorage.getItem("isMobileApp") && (
                            <img
                              src={phonebook}
                              width={42}
                              alt=""
                              className="absolute right-0 bottom-12  rounded-lg  p-1 border border-slate-300 dark:bg-white "
                              style={{ right: -2, bottom: 0 }}
                              onClick={(e) => {
                                e.preventDefault();
                                getPhoneNumber();
                              }}
                            />
                          )}

                          <span>
                            Please enter a valid receiver phone number
                          </span>
                        </label>
                      </div>
                    </div>
                  </label>
                </div>
                {formData.contact_name && (
                  <p className="m-0 p-0" style={{ fontSize: 10 }}>
                    {formData.contact_name}
                  </p>
                )}
              </div>

              <div className="w-full">
                <div className=" relative ">
                  <label>
                    <div className="flex justify-between">
                      <div className="font-medium text-gray-700 dark:text-gray-200  text-sm md:text-base">
                        Amount to Recharge
                      </div>
                      <div className="font-medium text-gray-700 dark:text-gray-200 text-sm md:text-base">
                        {user.data &&
                          `Balance: ₦ ${CurrencyFormat(
                            user.data.wallet_balance
                          )}`}
                      </div>
                    </div>
                    <input
                      type="text"
                      inputMode="numeric"
                      id="amount"
                      focused={focused.toString()}
                      required
                      autoComplete="off"
                      pattern="^[0-9]{2,6}$"
                      className=" rounded-lg    flex-1 appearance-none border border-slate-300 w-full py-2 px-4 md:py-3.5 md:px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-primary-orange   focus:   mt-1"
                      placeholder="Min ₦50, Max ₦10,000"
                      name="amount"
                      onBlur={handleFocus}
                      onChange={(e) => {
                        formOnChange(e);
                      }}
                      value={formData.amount}
                    />

                    <span>Please Enter valid amount</span>
                  </label>
                </div>
              </div>

              <div className="text-red-500 flex flex-col font-medium text-xs md:text-sm">
                {formData.network && (
                  <p className="">
                    Wallet Discount:{" "}
                    {100 - appData.airtime_plans[formData.network].wallet_price}
                    %
                  </p>
                )}
                {formData.network && (
                  <p className="mt-2">
                    ATM Discount:{" "}
                    {100 - appData.airtime_plans[formData.network].atm_price}%
                  </p>
                )}
              </div>

              <PaymentType
                sending={sending}
                setFocused={setFocused}
                focused={focused}
                handleFocus={handleFocus}
              />
            </div>
          </div>
        </div>
      </form>
    </Layout>
  );
}

export default AirtimePurchase;
